import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Autocomplete,
  Grid,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PaginationComponent from "../../Components/pagination";
import BarChartIcon from "@mui/icons-material/BarChart";

export const GenrateTrainingReportList = ({
  getAllDistrictData,
  getDistrictWiseTalukaData,
  getTalukaWisePanchayat,
  getAllTrainingModuleData,
  FetchApprovedTrainingData,
  FetchAllApprovedTrainingData,
  fetchDateWiseApprovedTrainingData,
  getAllTrainers,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  //data variables
  const [city, setCity] = useState("");
  const [taluka, setTaluka] = useState("");
  const [panchayat, setPanchayat] = useState("");
  const [trainerData, setTrainerData] = useState([]);
  const [ModuleData, setModuleData] = useState([]);
  const [type, setType] = useState("");
  const [TalukaData, setTalukaData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [PanchayatData, setPanchayatData] = useState([]);

  //filter
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [dateError, setdateError] = useState(false);
  const [InvalidDate, setInvalidDate] = useState(false);

  const [searchTrainerError, setsearchTrainerError] = useState(false);
  const [searchTrainer, setsearchTrainer] = useState(null);
  const [userData, setuserData] = useState([]);

  //Error Variables
  const [cityError, setcityError] = useState(false);
  const [TalukaError, setTalukaError] = useState(false);
  const [PanchayatError, setPanchayatError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  //Table Pagination variables
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [noOfPages, setNoOfPages] = useState();

  //Table Pagination Functions
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event);
    setPage(1);
  };

  const validateDate = () => {
    let IsformValid = true;

    if (!city) {
      IsformValid = false;
      setcityError(true);
    }
    if (!taluka) {
      IsformValid = false;
      setTalukaError(true);
    }
    if (!panchayat) {
      IsformValid = false;
      setPanchayatError(true);
    }
    if (!type) {
      IsformValid = false;
      setTypeError(true);
    }

    return IsformValid;
  };

  const getAllList = async () => {
    setLoader(true);
    const result = await FetchAllApprovedTrainingData({
      documentsPerPage: rowsPerPage,
      page: page,
    });
    if (result) {
      // console.log("All List", result)
      setTrainerData(result);
      setNoOfPages(result.noOfPages);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  //GET Trianer data
  const getTrainerData = async () => {
    setcityError(false);
    setTalukaError(false);
    setPanchayatError(false);
    setTypeError(false);
    setLoader(true);
    if (validateDate()) {
      // console.log("Setting Trainer data");
      const result = await FetchApprovedTrainingData({
        trainingModule_id: type?._id,
        distric_id: city?._id,
        grampanchayat_id: panchayat?._id,
      });
      if (result) {
        // console.log("Trainer Data", result)
        setTrainerData(result);
        setNoOfPages(result.noOfPages);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };
  const getDistrict = async () => {
    const result = await getAllDistrictData();

    if (result !== "No data found") {
      setcityData(result);
    }
  };

  const getTaluka = async () => {
    setTalukaError(false);
    const result = await getDistrictWiseTalukaData({
      distric_id: city?._id,
    });
    if (result !== "No data found") {
      setTalukaData(result);
      // console.log("Taluka list", result)
    } else {
      setTalukaError(true);
    }
  };

  const getPanchayat = async () => {
    setPanchayatError(false);
    const result = await getTalukaWisePanchayat({
      distric_id: city?._id,
      taluka_id: taluka?._id,
    });
    if (result !== "No data found") {
      setPanchayatData(result);
    } else {
      setPanchayatError(true);
    }
  };

  const getModule = async () => {
    const result = await getAllTrainingModuleData();
    if (result !== "No data found") {
      setModuleData(result);
      // console.log("ModuleList", result)
    }
  };

  useEffect(() => {
    getDistrict();
    getModule();
    // getAllList();
    getTrainers();
  }, []);

  useEffect(() => {
    if (searchTrainer) {
      getListByTrainer();
    } else {
      getAllList();
    }
  }, [page, rowsPerPage, searchTrainer]);

  useEffect(() => {
    if (city) {
      getTaluka();
    }
  }, [city]);

  useEffect(() => {
    if (taluka) {
      getPanchayat();
    }
  }, [taluka]);

  useEffect(() => {
    if (searchTrainer === null) {
      getAllList();
    }
  }, [searchTrainer]);

  const getDateWise = async () => {
    // console.log("GET DATE WISE")
    setLoader(true);
    setdateError(false);
    setInvalidDate(false);
    if (endDate < startDate) {
      // console.log("Invalid")
      setLoader(false);
      setInvalidDate(true);
    } else {
      if (startDate !== null && endDate !== null) {
        // console.log(startDate)
        // console.log(endDate)
        const result = await fetchDateWiseApprovedTrainingData({
          trainingDate: moment(startDate.$d).format("YYYY-MM-DD"),
          trainingDateEnd: moment(endDate.$d).format("YYYY-MM-DD"),
        });
        if (result) {
          // console.log("Date Wise", result)
          setTrainerData(result);
          setNoOfPages(result.noOfPages);
          setLoader(false);
        } else {
          setLoader(false);
        }
      } else {
        setLoader(false);
        setdateError(true);
      }
    }
  };

  const getTrainers = async () => {
    const result = await getAllTrainers();
    if (result) {
      setuserData(result);
    }
  };

  const getListByTrainer = async () => {
    setLoader(true);
    const result = await FetchAllApprovedTrainingData({
      documentsPerPage: rowsPerPage,
      page: page,
      trainer_id: searchTrainer._id,
    });
    console.log(result);
    if (result !== "No data fonund" && result !== undefined) {
      // console.log("Getting Data", result);
      setTrainerData(result);
      setNoOfPages(result.noOfPages);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getSingleUser = (value) => {
    setLoader(true);
    // console.log(value)
    if (value !== null) {
      let arr = trainerData.filter(
        (item) =>
          item?.trainer_idAll[0] === value?._id ||
          item?.trainer_idAll[1] === value?._id
      );
      // console.log("USERWISE", arr)
      setLoader(false);
      setTrainerData(arr);
    }
  };

  return (
    <div className={classes.main}>
      <center>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>
          Genrate Training Report
        </Typography>
      </center>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
          flexWrap: "wrap",
        }}
      >
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">District</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-Bank"
            label="District"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            disabled={cityData === undefined || cityData.length === 0}
          >
            {cityData &&
              cityData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.districtName}
                  </MenuItem>
                );
              })}
          </Select>
          {cityError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select District
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">Taluka</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-Bank"
            label="Taluka"
            value={taluka}
            disabled={TalukaData.length === 0}
            onChange={(e) => {
              setTaluka(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {TalukaData &&
              TalukaData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.talukaName}
                  </MenuItem>
                );
              })}
          </Select>
          {TalukaError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Taluka
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">School</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-month"
            label="School"
            value={panchayat}
            disabled={PanchayatData.length === 0}
            onChange={(e) => {
              setPanchayat(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {PanchayatData &&
              PanchayatData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.schoolName}
                  </MenuItem>
                );
              })}
          </Select>
          {PanchayatError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select School
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">Training Module</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-month"
            label="Training Module"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            disabled={ModuleData === undefined || ModuleData.length === 0}
          >
            {ModuleData &&
              ModuleData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.trainingModuleName}
                  </MenuItem>
                );
              })}
          </Select>
          {typeError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Training Module
            </span>
          ) : null}
        </FormControl>
        <Button
          sx={{ m: 1, marginRight: 3 }}
          variant="contained"
          style={{ fontSize: 14 }}
          onClick={() => getTrainerData()}
        >
          Get Data
        </Button>
      </div>
      <div style={{ marginBottom: 30 }}>
        <Button variant="contained" onClick={() => getAllList()}>
          GET ALL Data
        </Button>
      </div>
      <Box style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                setstartDate(newValue);
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => (
                <TextField sx={{ width: 150 }} {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              minDate={startDate}
              onChange={(newValue) => {
                setendDate(newValue);
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => (
                <TextField sx={{ width: 150 }} {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{ margin: 5, width: 170, height: 50 }}
            onClick={() => getDateWise()}
          >
            Get Data Datewise
          </Button>
          {dateError ? (
            <span style={{ color: "red" }}>*Please Select Both Dates</span>
          ) : null}
          {InvalidDate ? (
            <span style={{ color: "red" }}>
              *End Date cannot be less than Start date
            </span>
          ) : null}
        </div>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
          marginBottom: 30,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Autocomplete
            id="Trainer-data"
            sx={{ width: 300 }}
            options={userData}
            autoHighlight
            disablePortal
            freeSolo
            disabled={userData === undefined || userData.length === 0}
            getOptionLabel={(option) =>
              option?.firstName +
              " " +
              option?.lastName +
              " - " +
              option?.contactNo
            }
            renderInput={(params) => (
              <TextField {...params} label="Search By Trainer" />
            )}
            // value={searchTrainer}
            onChange={(event, newValue) => {
              setsearchTrainer(newValue);
              // getSingleUser(newValue)
            }}
          />
          {searchTrainerError ? (
            <span style={{ color: "red" }}>*Please Select trainer first</span>
          ) : null}
        </div>
        {/* <Button
          variant="contained"
          disabled={searchTrainer === null}
          style={{ margin: 10 }}
          onClick={() => getSingleUser(searchTrainer)}
        >
          Get Trainer's Data
        </Button> */}
      </div>
      {loader ? (
        <CircularProgress color="primary" />
      ) : (
        <Paper sx={{ overflow: "hidden", width: "90%" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>SR No.</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Trainer Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Support Trainer</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>District</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>GramPanchyat</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Training Module</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Training Start Date</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Training Timing</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Documents Uploaded</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Action</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trainerData !== "No data found" &&
                trainerData.data &&
                trainerData.data.length > 0 ? (
                  trainerData.data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainerNameAll[0]
                            ? row?.trainerNameAll[0]
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainerNameAll[1]
                            ? row?.trainerNameAll[1]
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.districtName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.grampanchayatName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainingModuleName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {moment(row?.startDate).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.shift ? row?.shift.toUpperCase() : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.docUploadedCount}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate("/admin/Create-training-report", {
                                state: row,
                              })
                            }
                          >
                            Create Report
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>NO Data Found</TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                <Typography variant="h6" component="div" textAlign="center">
                  <IconButton>
                    <BarChartIcon />
                  </IconButton>
                  Total - <strong>{trainerData?.count}</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                display="flex"
                justifyContent="flex-end"
              >
                <PaginationComponent
                  currentPage={page}
                  totalPages={noOfPages}
                  onPageChange={handleChangePage}
                  onDocsPerPageChange={handleChangeRowsPerPage}
                  docsPerPage={rowsPerPage}
                />
              </Grid>
            </Grid>
          </Box>
          {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              // count={count ? count : 0}
              count={trainerData !== "No data found" && trainerData?.length > 0 ? trainerData?.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Paper>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "1200px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1400px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
  image: {
    width: 80,
    height: 80,
  },
  imageApprove: {
    width: 80,
    height: 80,
    border: "5px solid green",
  },
  imageReject: {
    width: 80,
    height: 80,
    border: "5px solid red",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
    img1: state.admin.img1,
    img2: state.admin.img2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getDistrictWiseTalukaData: (data) =>
      dispatch(Action.getDistrictWiseTalukaData(data)),
    getTalukaWisePanchayat: (data) =>
      dispatch(Action.getTalukaWisePanchayat(data)),
    getAllTrainingModuleData: () => dispatch(Action.getAllTrainingModuleData()),
    FetchApprovedTrainingData: (data) =>
      dispatch(Action.FetchApprovedTrainingData(data)),
    FetchAllApprovedTrainingData: (data) =>
      dispatch(Action.FetchAllApprovedTrainingData(data)),
    
    fetchDateWiseApprovedTrainingData: (data) =>
      dispatch(Action.fetchDateWiseApprovedTrainingData(data)),
    getAllTrainers: () => dispatch(Action.getAllTrainers()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenrateTrainingReportList);
