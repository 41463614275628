import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Autocomplete,
  Grid,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PaginationComponent from "../../Components/pagination";
import BarChartIcon from "@mui/icons-material/BarChart";

const TrainerData = [
  {
    Name: "ABC",
    Mobile: "8529637410",
    Email: "ABC@123gmail.com",
    GramPanchyat: "Maval",
    startDate: "20-10-2022",
    Module: "Type_1",
    Address: "Pune",
  },
  {
    Name: "PQR",
    Mobile: "789456130",
    Email: "PQR@123gmail.com",
    GramPanchyat: "Mulshi",
    startDate: "4-10-2022",
    Module: "Type_2",
    Address: "Pune",
  },
  {
    Name: "XYZ",
    Mobile: "3216974036",
    Email: "XYZ@123gmail.com",
    GramPanchyat: "Beed",
    startDate: "2-10-2022",
    Module: "Type_1",
    Address: "Pune",
  },
  {
    Name: "LMN",
    Mobile: "789456130",
    Email: "LMN@123gmail.com",
    GramPanchyat: "Bhugaon",
    startDate: "30-10-2022",
    Module: "Type_2",
    Address: "Pune",
  },
];

export const UploadTraningDocslist = ({
  getDistrictWiseTalukaData,
  getAllDistrictData,
  userDetails,
  getTalukaWisePanchayat,
  getAllTrainingModuleData,
  getAllUplodedTrainerList,
  getModuleWiseTrainerList,
  FinalAprroveTraining,
  setActiveList,
  getDateWiseTrainerList,
  getAllTrainers,
  getAllUplodedTrainerListByTrainerId,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  //data variables
  const [city, setCity] = useState("");
  const [taluka, setTaluka] = useState("");
  const [panchayat, setPanchayat] = useState("");
  const [trainerData, setTrainerData] = useState([]);
  const [type, setType] = useState("");
  const [ModuleData, setModuleData] = useState([]);

  //filter
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [dateError, setdateError] = useState(false);
  const [InvalidDate, setInvalidDate] = useState(false);

  const [FinalApproveEvidenceId, setFinalApproveEvidenceId] = useState("");
  const [FinalApproveModuleId, setFinalApproveModuleId] = useState("");
  const [FinalApproveDistrictId, setFinalApproveDistrictId] = useState("");
  const [FinalApprovePanchayatId, setFinalApprovePanchayatId] = useState("");
  const [openFinal, setopenFinal] = useState(false);

  const [searchTrainerError, setsearchTrainerError] = useState(false);
  const [searchTrainer, setsearchTrainer] = useState(null);
  const [userData, setuserData] = useState([]);

  //Error Variables
  const [cityError, setcityError] = useState(false);
  const [TalukaError, setTalukaError] = useState(false);
  const [PanchayatError, setPanchayatError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [TalukaData, setTalukaData] = useState([]);
  const [DistrictData, setDistrictData] = useState([]);
  const [GrampanchayatData, setGrampanchayatData] = useState([]);

  //Table Pagination variables
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [noOfPages, setNoOfPages] = useState();

  //Table Pagination Functions
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event);
    setPage(1);
  };

  const getDistrict = async () => {
    const result = await getAllDistrictData();
    if (result !== "No data found") {
      setDistrictData(result);
    }
  };

  const getTaluka = async () => {
    setTalukaError(false);
    const result = await getDistrictWiseTalukaData({
      distric_id: city?._id,
    });
    if (result !== "No data found") {
      setTalukaData(result);
      // console.log("Taluka list", result)
    } else {
      setTalukaError(true);
    }
  };

  const getPanchayat = async () => {
    setPanchayatError(false);
    const result = await getTalukaWisePanchayat({
      distric_id: city?._id,
      taluka_id: taluka?._id,
    });
    if (result !== "No data found") {
      setGrampanchayatData(result);
    } else {
      setPanchayatError(true);
    }
  };

  const getModule = async () => {
    const result = await getAllTrainingModuleData();
    if (result !== "No data found") {
      setModuleData(result);
      // console.log("ModuleList", result)
    }
  };

  useEffect(() => {
    getDistrict();
    getModule();
    getTrainerData();
  }, []);

  useEffect(() => {
    if (searchTrainer) {
      getListByTrainer();
    } else {
      AllUplodedTrainerData();
    }
  }, [page, rowsPerPage, searchTrainer]);

  React.useEffect(() => {
    if (city) {
      getTaluka();
      setTaluka("");
      setPanchayat("");
    }
  }, [city]);

  React.useEffect(() => {
    if (taluka) {
      getPanchayat();
      setPanchayat("");
    }
  }, [taluka]);

  useEffect(() => {
    if (searchTrainer === null) {
      AllUplodedTrainerData();
    }
  }, [searchTrainer]);

  const getModuleWiseTrainerData = async () => {
    setcityError(false);
    setTalukaError(false);
    setPanchayatError(false);
    setTypeError(false);
    if (validate()) {
      setLoader(true);
      const result = await getModuleWiseTrainerList({
        grampanchayat_id: panchayat?._id,
        trainingModule_id: type?._id,
        documentsPerPage: rowsPerPage,
        page: page,
      });
      if (result) {
        setTrainerData(result);
        setPage(1);
        setNoOfPages(result.noOfPages);
        setLoader(false);
        // console.log("MODULE WISE TRAINER", result)
      }
    }
  };

  const AllUplodedTrainerData = async () => {
    setLoader(true);
    const result = await getAllUplodedTrainerList({
      documentsPerPage: rowsPerPage,
      page: page,
    });
    if (result !== "No data found") {
      setTrainerData(result);
      setLoader(false);
      setPage(1);
      setNoOfPages(result.noOfPages);
      // console.log(result)
    }
  };

  const getListByTrainer = async () => {
    setLoader(true);
    const result = await getAllUplodedTrainerListByTrainerId({
      documentsPerPage: rowsPerPage,
      page: page,
      trainer_id: searchTrainer._id,
    });
    console.log(result);
    if (result !== "No data fonund" && result !== undefined) {
      // console.log("Getting Data", result);
      setTrainerData(result);
      setNoOfPages(result.noOfPages);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const FinalAprroveTrainingData = async () => {
    const result = await FinalAprroveTraining({
      trainingsssigntrainers_id: FinalApproveEvidenceId,
      trainingModule_id: FinalApproveModuleId,
      distric_id: FinalApproveDistrictId,
      grampanchayat_id: FinalApprovePanchayatId,
      updatedBy: userDetails?._id,
    });

    if (result) {
      // console.log("Training Approved", result)
      AllUplodedTrainerData();
      navigate("/admin/Approved-document");
      setActiveList("/admin/Approved-document");
    }
  };

  const openFinalApproval = (row) => {
    setFinalApproveEvidenceId(row?._id);
    setFinalApproveDistrictId(row?.distric_id);
    setFinalApprovePanchayatId(row?.grampanchayat_id);
    setFinalApproveModuleId(row?.trainingModule_id);
    setopenFinal(true);
  };

  const validate = () => {
    let IsformValid = true;

    if (!city) {
      IsformValid = false;
      setcityError(true);
    }

    if (!taluka) {
      IsformValid = false;
      setTalukaError(true);
    }

    if (!panchayat) {
      IsformValid = false;
      setPanchayatError(true);
    }

    if (!type) {
      IsformValid = false;
      setTypeError(true);
    }

    return IsformValid;
  };

  const getDateWise = async () => {
    setLoader(true);
    setdateError(false);
    setInvalidDate(false);
    if (endDate < startDate) {
      // console.log("Invalid")
      setLoader(false);
      setInvalidDate(true);
    } else {
      if (startDate !== null && endDate !== null) {
        const result = await getDateWiseTrainerList({
          trainingDate: moment(startDate.$d).format("YYYY-MM-DD"),
          trainingDateEnd: moment(endDate.$d).format("YYYY-MM-DD"),
          documentsPerPage: rowsPerPage,
          page: page,
        });
        if (result) {
          setPage(1);
          setNoOfPages(result.noOfPages);
          setTrainerData(result);
          setLoader(false);
        } else {
          setLoader(false);
        }
      } else {
        setLoader(false);
        setdateError(true);
      }
    }
  };

  const getTrainerData = async () => {
    const result = await getAllTrainers();
    if (result) {
      // console.log("Trainer list", result)
      setuserData(result);
    }
  };

  const getSingleUser = (value) => {
    // console.log(value)
    if (value !== null) {
      let arr = trainerData.filter(
        (item) =>
          item?.trainer_idAll[0] === value?._id ||
          item?.trainer_idAll[1] === value?._id
      );
      // console.log("USERWISE", arr)
      setTrainerData(arr);
    }
  };

  console.log("traineryData", trainerData);

  return (
    <div className={classes.main}>
      <center>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>
          Upload Training Document
        </Typography>
      </center>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
          flexWrap: "wrap",
        }}
      >
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">District</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-Bank"
            label="District"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            disabled={DistrictData === undefined || DistrictData?.length === 0}
          >
            {DistrictData !== undefined &&
              DistrictData.length !== 0 &&
              DistrictData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.districtName}
                  </MenuItem>
                );
              })}
          </Select>
          {cityError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select City
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">Taluka</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-Bank"
            label="Taluka"
            value={taluka}
            disabled={TalukaData.length === 0}
            onChange={(e) => {
              setTaluka(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {TalukaData &&
              TalukaData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.talukaName}
                  </MenuItem>
                );
              })}
          </Select>
          {TalukaError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Taluka
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">School</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-month"
            label="School"
            value={panchayat}
            disabled={GrampanchayatData.length === 0}
            onChange={(e) => {
              setPanchayat(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {GrampanchayatData &&
              GrampanchayatData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.schoolName}
                  </MenuItem>
                );
              })}
          </Select>
          {PanchayatError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select School
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">Module</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-month"
            label="Type"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            disabled={ModuleData === undefined || ModuleData.length === 0}
          >
            {ModuleData &&
              ModuleData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.trainingModuleName}
                  </MenuItem>
                );
              })}
          </Select>
          {typeError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Training Module
            </span>
          ) : null}
        </FormControl>
        <Button
          sx={{ m: 1 }}
          variant="contained"
          style={{ fontSize: 14 }}
          onClick={() => getModuleWiseTrainerData()}
        >
          Get Data
        </Button>
      </div>
      <div style={{ marginBottom: 30 }}>
        <Button
          variant="contained"
          onClick={() => {
            AllUplodedTrainerData();
            setCity("")
            setType("")
            setTaluka("")
            setPanchayat("")
          }}

        >
          GET ALL Data
        </Button>
      </div>
      <Box style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                setstartDate(newValue);
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => (
                <TextField sx={{ width: 150 }} {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              minDate={startDate}
              onChange={(newValue) => {
                setendDate(newValue);
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => (
                <TextField sx={{ width: 150 }} {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{ margin: 5, width: 170, height: 50 }}
            onClick={() => getDateWise()}
          >
            Get Data Datewise
          </Button>
          {dateError ? (
            <span style={{ color: "red" }}>*Please Select Both Dates</span>
          ) : null}
          {InvalidDate ? (
            <span style={{ color: "red" }}>
              *End Date cannot be less than Start date
            </span>
          ) : null}
        </div>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
          marginBottom: 30,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Autocomplete
            id="Trainer-data"
            sx={{ width: 300 }}
            options={userData}
            autoHighlight
            disablePortal
            freeSolo
            disabled={userData === undefined || userData.length === 0}
            getOptionLabel={(option) =>
              option?.firstName +
              " " +
              option?.lastName +
              " - " +
              option?.contactNo
            }
            renderInput={(params) => (
              <TextField {...params} label="Search By Trainer" />
            )}
            // value={searchTrainer}
            onChange={(event, newValue) => {
              setsearchTrainer(newValue);
              // getSingleUser(newValue)
            }}
          />
          {searchTrainerError ? (
            <span style={{ color: "red" }}>*Please Select trainer first</span>
          ) : null}
        </div>
        {/* <Button variant="contained" disabled={searchTrainer === null} style={{ margin: 10 }} onClick={() => getSingleUser(searchTrainer)}>Get Trainer's Data</Button> */}
      </div>
      {loader ? (
        <center>
          <CircularProgress color="primary" />
        </center>
      ) : (
        <Paper sx={{ overflow: "hidden", width: "95%" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>SR No.</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Trainer Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>District</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>School</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Module</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Start Date</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Timing</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Documents Uploaded</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Action</strong>
                  </TableCell>
                  {/* <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Edit</strong>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {trainerData !== undefined &&
                trainerData.Data &&
                trainerData.Data.length > 0 ? (
                  trainerData.Data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainerName
                            ? row?.trainerName + ' - ' + row?.trainerMobileAll[0]
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.districtName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.grampanchayatName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainingModuleName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {moment(row?.startDate).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.shift ? row?.shift.toUpperCase() : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.docUploadedCount}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          <Button
                            variant="contained"
                            style={{ margin: 5 }}
                            onClick={() =>
                              navigate("/admin/view-trainer-docs", {
                                state: row,
                              })
                            }
                          >
                            Verify Documents
                          </Button>
                          {/* <Button variant="contained" style={{ margin: 5 }} onClick={() => openFinalApproval(row)} disabled={row?.docApprovedCount === 0} >
                                Final Approval
                              </Button> */}
                        </TableCell>

                        {/* <TableCell
                              style={{ fontSize: 16, textAlign: "center" }}
                            >
                              <Button
                                variant="contained"
                                style={{ margin: 5 }}
                                onClick={() =>
                                  navigate("/admin/edit-trainer-docs", {
                                    state: row,
                                  })
                                }
                              >
                                Edit
                              </Button>
                            </TableCell> */}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>NO Data Found</TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                <Typography variant="h6" component="div" textAlign="center">
                  <IconButton>
                    <BarChartIcon />
                  </IconButton>
                  Total - <strong>{trainerData?.count}</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                display="flex"
                justifyContent="flex-end"
              >
                <PaginationComponent
                  currentPage={page}
                  totalPages={noOfPages}
                  onPageChange={handleChangePage}
                  onDocsPerPageChange={handleChangeRowsPerPage}
                  docsPerPage={rowsPerPage}
                />
              </Grid>
            </Grid>
          </Box>
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            // count={count ? count : 0}
            count={trainerData !== "No data found" && trainerData?.length > 0 ? trainerData?.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      )}

      <Modal
        className={classes.middlePosition}
        open={openFinal}
        onClose={(e) => {
          e.preventDefault();
          setopenFinal(false);
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Want to Approve this Training
              </Typography>
            </Stack>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setopenFinal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <h3>Do you want to Approve this Training ?</h3>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "green", width: 100, margin: 5 }}
                onClick={() => {
                  setopenFinal(false);
                  FinalAprroveTrainingData();
                }}
              >
                Approve
              </Button>

              <Button
                variant="contained"
                style={{ backgroundColor: "grey", width: 100, margin: 5 }}
                onClick={() => {
                  setopenFinal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "1200px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1400px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getDistrictWiseTalukaData: (data) =>
      dispatch(Action.getDistrictWiseTalukaData(data)),
    getTalukaWisePanchayat: (data) =>
      dispatch(Action.getTalukaWisePanchayat(data)),
    getAllTrainingModuleData: () => dispatch(Action.getAllTrainingModuleData()),
    getAllUplodedTrainerList: (data) =>
      dispatch(Action.getAllUplodedTrainerList(data)),
    getAllUplodedTrainerListByTrainerId: (data) =>
      dispatch(Action.getAllUplodedTrainerListByTrainerId(data)),
    getModuleWiseTrainerList: (data) =>
      dispatch(Action.getModuleWiseTrainerList(data)),
    FinalAprroveTraining: (data) => dispatch(Action.FinalAprroveTraining(data)),
    setActiveList: (data) => dispatch(Action.setActiveList(data)),
    getDateWiseTrainerList: (data) =>
      dispatch(Action.getDateWiseTrainerList(data)),
    getAllTrainers: () => dispatch(Action.getAllTrainers()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadTraningDocslist);
