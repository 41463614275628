import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Card, CircularProgress} from "@mui/material";
import * as Action from "../../actions/adminAction"
import Image1 from "../../Assets/Image/satara_Mandave.JPG"
import Image2 from "../../Assets/Image/satara_Mandave_2.jpg"
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { BASE_URL } from '../../utils/urls';
import docImg from '../../Assets/Image/doc.png'
import videoImg from '../../Assets/Image/Video.png'
require('../../App.css')

export const ViewRejectedItems = ({ userDetails }) => {

  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const Trainer = location.state

  const [openModal, setOpenModal] = useState(false);
  const [categoryArray, setCategoryArray] = useState();
  const [loader, setLoader] = useState();



  // console.log("Details", userDetails)


  useEffect(() => {
    // console.log("TEST EFFECT")
    fetchUploadedDocList()
  }, [])

  const fetchUploadedDocList = async () => {
    setLoader(true)
    const url = BASE_URL + "/trainerevidence/trainerevidence/grampanchayat";
    const payload = {
      grampanchayat_id: Trainer?.grampanchayat_id,
      trainingModule_id: Trainer?.trainingModule_id
    }
    const opt = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }
    fetch(url, opt)
      .then(res => {
        return res.json();
      })
      .then(data => {
        // console.log("DOCData::::???", data);
        data !== undefined && data !== null && setCategoryArray(data)
        setLoader(false)
        // fetchCategory2(data)

      })  
      .catch(err => {setLoader(false)})
  }



  return (
    <div className={classes.main}>
      <Card className={classes.card} style={{ backgroundColor: 'whitesmoke' }}>
        <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
          <IconButton style={{ margin: 10, padding: 0 }}>
            <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#1d5a96' }} sx={{
            }} >Trainer Data</Typography>
          </IconButton>
        </div>
        <div>
          <Table>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Assignment Date:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null ? dayjs(Trainer.startDate,).format("DD MMM YYYY") : '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>School:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null ? Trainer.grampanchayatName : "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>District:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null ? Trainer.districtName : "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Module:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null ? Trainer.trainingModuleName : "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Timing:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null ? Trainer?.shift ? Trainer?.shift?.toUpperCase() : "-" : "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Trainer</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null ? Trainer.trainerNameAll[0] : "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Mobile</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null ? Trainer.trainerMobileAll[0] : "-"}</TableCell>
            </TableRow>
           
          </Table>
          <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>Categories</Typography>
          {loader? <CircularProgress color='primary'></CircularProgress>:<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50, flexWrap: 'wrap', padding: '0px 10%' }}>
            {categoryArray!==undefined && categoryArray!==null && categoryArray.length>0 && categoryArray!== 'No data found' ? categoryArray?.map((row) => {
              let x = row?.category?.filter((item2) => item2?.status === "unprocess")
              // console.log("XValueSORT",categoryArray.sort())
              if(x.length>0){
                return (
                  <div style={{ width: '90%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#1d5a96', margin: 10 }}>
                      <Typography style={{ fontSize: 24, fontWeight: 700, marginLeft: 10, color: 'white' }}> {row?._id}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
                      {row?.category?.map((item2) => {
  
                        if (item2?.status === "unprocess") {
  
                          if (item2?.categoryDoc.split('/')[0] === 'image') {
                            return (<div className='imgContainer'>
                              <div className='img'>
                                <a target="_blank" href={item2?.documentUrl}><img src={item2?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                              </div>
                            </div>)
                          } else if (item2?.categoryDoc.split('/')[0] === 'video') {
                            return (<div className='imgContainer'>
                              <div className='img'>
                                <a target="_blank" href={item2?.documentUrl}><img src={videoImg} style={{ height: '100%', width: '100%' }} /></a>
                              </div>
                            </div>)
                          }
                          else{
                            return (<div className='imgContainer'>
                              <div className='img'>
                                <a target="_blank" href={item2?.documentUrl}><img src={docImg} style={{ height: '100%', width: '100%' }} /></a>
                              </div>
                            </div>)
                          }
  
                        }
                        else return null
                      })}
                    </div>
                  </div>
                )
              }
              
            }) : "No Data Found"}
          </div>}
          
        </div>
        <center>
          <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)}>Back</Button>
        </center>
      </Card>
      <Modal
        className={classes.middlePosition}
        open={openModal}
        onClose={(e) => {
          e.preventDefault();
          setOpenModal(!openModal);
        }}
      >
        <Paper className={classes.profilemodal} style={{ marginTop: 70 }}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Activity</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setOpenModal(!openModal);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px', }}>Activity</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>RCID</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Category</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>School</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Activity Date</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>Activity_Name</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>RCID_Number</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>Category_Name</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>School</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>12/12/2022</div>
            </div>
          </div>
          <h3>Images</h3>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
            <div className='imgContainer'>
              <div className='img'>
                <a target="_blank" href={Image1}><img src={Image1} style={{ height: '100%', width: '100%' }} /></a>
              </div>
            </div>
            <div className='imgContainer'>
              <div className='img'>
                <a target="_blank" href={Image2}><img src={Image2} style={{ height: '100%', width: '100%' }} /></a>
              </div>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '100%',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '1000px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
  image: {
    width: 150,
    height: 150
  },
  imageApprove: {
    backgroundColor: 'green',
    color: 'white'
  },
  imageReject: {
    backgroundColor: 'red',
    color: 'white'
  }
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRejectedItems)